<template>
  <div class="orderSubmissionContainer">
    <div class="align-right">
      <button class="btn button-primary" @click="fetchLastSavedData">Fetch Last Saved Data</button>
    </div>
    <div class="loading" v-if="loadIndicator && !loadFirstTime">
      <Loading class="py-5" />
    </div>
    <template>
      <form
        v-if="this.savedUpcOrders"
        id="finalOrder"
        @submit.prevent="handleBBSubmitDummy"
        class="table_material table_upc row_flex"
      >
        <div v-for="bucket in Object.keys(this.savedUpcOrders)" :key="bucket">
          <div class="upload-bb-header">
            <p>{{ bucket }}</p>
          </div>
          <table class="table mb-0">
            <thead>
              <tr class="border-top-none text-center">
                <th></th>
                <th></th>
                <th v-for="month in months" :key="month.shortName">
                  {{ month.shortName }}
                </th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody class="orderTableClass">
              <tr>
                <td scope="row">Req delivery date</td>
                <td scope="row"></td>
                <td v-for="month in months" :key="month.shortName">
                  <input
                    type="date"
                    v-model="reqDeliveryDate[bucket][month.shortName]"
                    :class="{
                      bgDisabledColor: getDisabledStatus(bucket, month.shortName),
                    }"
                    @input="($event) => handleDateChange($event, bucket, month.shortName)"
                    :min="getMinReqDelDate(bucket, month.shortName)"
                    :disabled="getDisabledStatus(bucket, month.shortName)"
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td scope="row">PO Number</td>
                <td scope="row"></td>
                <td v-for="month in months" :key="month.shortName">
                  <input
                    type="text"
                    min="0"
                    v-model="poNumberObj[bucket][month.shortName]"
                    :class="{
                      bgDisabledColor: getDisabledStatus(bucket, month.shortName),
                    }"
                    @input="($event) => handlePoNumberChange($event, bucket, month.shortName)"
                    :disabled="getDisabledStatus(bucket, month.shortName)"
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td scope="row">Order QTY</td>
                <td scope="row"></td>
                <td v-for="month in months" :key="month.shortName">
                  <input type="number" :value="getValueFromSavedOrders(bucket, month.shortName)" disabled />
                </td>
                <td>
                  <input
                    type="text"
                    min="0"
                    :class="{
                      bgDisabledColor: getDisabledStatusForTotal(bucket),
                    }"
                    :value="getTotalValueFromSavedOrders(bucket)"
                    disabled
                  />
                </td>
              </tr>
              <tr v-for="(catandSubCat, index) in getCatAndSubCat(bucket)" :key="index">
                <td scope="row" class="catSubCatRow">
                  {{ catandSubCat.split("-")[0] }}
                </td>
                <td scope="row" class="catSubCatRow">
                  {{ catandSubCat.split("-")[1] }}
                </td>
                <td v-for="month in months" :key="month.shortName">
                  <input
                    type="number"
                    :value="getValueFromSavedOrdersForCat(bucket, catandSubCat, month.shortName)"
                    disabled
                  />
                </td>
                <td>
                  <input type="number" :value="getTotalValueFromSavedOrdersForCat(bucket, catandSubCat)" disabled />
                </td>
              </tr>
              <tr>
                <td scope="row">Order G$</td>
                <td scope="row"></td>
                <td v-for="month in months" :key="month.shortName">
                  {{ getOrderPrice(bucket, month.shortName) }}
                </td>
                <td>
                  {{ getOrderPriceForTotal(bucket) }}
                </td>
              </tr>
              <tr>
                <td scope="row">Ordered QTY</td>
                <td scope="row"></td>
                <td v-for="month in months" :key="month.shortName">
                  {{ getSum(submittedData, { month: month.value, bucket: bucket }, "order_qty") }}
                </td>
                <td>{{ getSum(submittedData, { bucket: bucket }, "order_qty") }}</td>
              </tr>
              <tr>
                <td scope="row"></td>
                <td scope="row">-</td>
                <td v-for="month in months" :key="month.shortName">
                  <button
                    type="submit"
                    class="btn btn-sm btn-outline-primary"
                    @click="submitOrder(bucket, month.shortName)"
                    :disabled="getDisabledStatus(bucket, month.shortName)"
                  >
                    Submit
                  </button>
                </td>
                <td>
                  <button
                    type="submit"
                    class="btn btn-sm btn-outline-primary totalButton"
                    @click="submitTotalOrder(bucket)"
                    :disabled="getDisabledStatusForTotal(bucket)"
                  >
                    Submit All
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
      <div v-if="!loadIndicator && !this.savedUpcOrders && !loadFirstTime">No orders were saved for this retailer</div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { API, graphqlOperation } from "aws-amplify";
import { saveFinalOrderDataBbResponse, deleteOrder } from "@/graphql/mutations";
import { fetchSubmittedToBbData, getUploadToBbUrlAndAuthKey } from "@/graphql/queries";
import Loading from "@/components/common/Loading";
import { currencySymbols, monthsObject } from "@/constants";
import { filtersMixin } from "@/mixins/filters";
import axios from "axios";
import { fetchOrdersData } from "@/graphql/queries";

export default {
  mixins: [filtersMixin],
  components: { Loading },
  data() {
    return {
      savedUpcOrders: null,
      reqDeliveryDate: {},
      poNumberObj: {},
      bbSubmitData: {},
      submittedData: [],
      loadIndicator: true,
      loadFirstTime: true,
      saveDataError: "",
      order_id: null,
      shipDateFromDB: [],
      uploadToBbUrl: "",
      uploadToBbUrlAuthKey: "",
      currencyType: "PR00",
    };
  },
  computed: {
    ...mapGetters(["user", "product", "bookingYear", "retailer", "months", "currentSeasonMutation", "catalogId"]),
  },
  created() {
    this.getUploadToBbUrlAndAuthKey();
  },
  methods: {
    handleDateChange(event, bucket, shortMonthName) {
      this.reqDeliveryDate[bucket][shortMonthName] = event.target.value;
    },
    handlePoNumberChange(event, bucket, shortMonthName) {
      this.poNumberObj[bucket][shortMonthName] = event.target.value;
    },
    getMinReqDelDate(bucket, shortMonthName) {
      return this.savedUpcOrders[bucket]["months"][shortMonthName]["shelfDate"];
    },
    getDisabledStatus(bucket, shortMonthName) {
      // if shelf date does not exist in api call then order was not made
      return !this.savedUpcOrders[bucket]["months"][shortMonthName]["shelfDate"];
    },
    getDisabledStatusForTotal(bucket) {
      let abledStatus = false;
      for (const month of this.months) {
        abledStatus = abledStatus || !this.getDisabledStatus(bucket, month.shortName);
      }
      return !abledStatus;
    },
    getValueFromSavedOrders(bucket, shortMonthName) {
      const materialObj = this.savedUpcOrders[bucket]["months"][shortMonthName]["materials"];
      let totalSum = 0;
      if (Object.keys(materialObj).length) {
        Object.keys(materialObj).forEach((item) => {
          totalSum += materialObj[item]["upcs"].reduce((acc, upcItem) => acc + upcItem["quantity"], 0);
        });
      }
      return totalSum;
    },
    getValueFromSavedOrdersForCat(bucket, catandSubCat, shortMonthName) {
      const category = catandSubCat.split("-")[0];
      const subCategory = catandSubCat.split("-")[1];
      const materialObj = this.savedUpcOrders[bucket]["months"][shortMonthName]["materials"];
      let totalSum = 0;
      if (Object.keys(materialObj).length) {
        Object.keys(materialObj).forEach((item) => {
          if (materialObj[item]["category"] === category && materialObj[item]["subCategory"] === subCategory) {
            totalSum += materialObj[item]["upcs"].reduce((acc, upcItem) => acc + upcItem["quantity"], 0);
          }
        });
      }
      return totalSum;
    },
    getTotalValueFromSavedOrdersForCat(bucket, catandSubCat) {
      let totalSum = 0;
      for (const month of this.months) {
        totalSum += this.getValueFromSavedOrdersForCat(bucket, catandSubCat, month.shortName);
      }
      return totalSum;
    },
    getTotalValueFromSavedOrders(bucket) {
      let totalSum = 0;
      for (const month of this.months) {
        totalSum += this.getValueFromSavedOrders(bucket, month.shortName);
      }
      return totalSum;
    },
    getPriceForMaterial(materialObj, currency, totalPrice) {
      if (Object.keys(materialObj).length) {
        Object.keys(materialObj).forEach((item) => {
          currency = materialObj[item]["currency"];
          totalPrice += materialObj[item]["upcs"].reduce(
            (acc, upcItem) => acc + upcItem["quantity"] * materialObj[item]["price"],
            0
          );
        });
      }
      return { currency, totalPrice };
    },
    getOrderPrice(bucket, shortMonthName) {
      const materialObj = this.savedUpcOrders[bucket]["months"][shortMonthName]["materials"];
      const { currency, totalPrice } = this.getPriceForMaterial(materialObj, "USD", 0);
      return totalPrice ? currencySymbols[currency] + " " + this.$options.filters.currencyFormat(totalPrice) : 0;
    },
    getOrderPriceForTotal(bucket) {
      let totalOrderPrice = 0;
      let finalCurrency = "USD";
      for (const month of this.months) {
        const materialObj = this.savedUpcOrders[bucket]["months"][month.shortName]["materials"];
        const { currency, totalPrice } = this.getPriceForMaterial(materialObj, "USD", 0);
        totalOrderPrice += totalPrice;
        finalCurrency = currency;
      }
      return totalOrderPrice
        ? currencySymbols[finalCurrency] + " " + this.$options.filters.currencyFormat(totalOrderPrice)
        : 0;
    },
    getCatAndSubCat(bucket) {
      const monthObj = this.savedUpcOrders[bucket]["months"];
      let catSubCatObj = {};
      Object.keys(monthObj).forEach((item) => {
        const materialObj = monthObj[item]["materials"];
        Object.keys(materialObj).forEach((itemKey) => {
          const str = materialObj[itemKey]["category"] + "-" + materialObj[itemKey]["subCategory"];
          catSubCatObj = { ...catSubCatObj, [str]: "" };
        });
      });
      return Object.keys(catSubCatObj);
    },
    async getUploadToBbUrlAndAuthKey() {
      this.uploadToBbUrl = "";
      this.uploadToBbUrlAuthKey = "";
      const uploadToBbUrlAndAuthKeyTmp = await API.graphql({
        query: getUploadToBbUrlAndAuthKey,
        variables: {
          application: "ASSORTMENT_PLANNING_TOOL",
          module: "PLANNING",
          sub_module: "UPLOAD_TO_BB",
        },
      });
      this.uploadToBbUrlAndAuthKey = uploadToBbUrlAndAuthKeyTmp.data.getUploadToBbUrlAndAuthKey;
      for (let i = 0; i < this.uploadToBbUrlAndAuthKey.length; i++) {
        if (this.uploadToBbUrlAndAuthKey[i].key_ === "AUTH_KEY") {
          this.uploadToBbUrlAuthKey = this.uploadToBbUrlAndAuthKey[i].value_;
        } else if (this.uploadToBbUrlAndAuthKey[i].key_ === "URL") {
          this.uploadToBbUrl = this.uploadToBbUrlAndAuthKey[i].value_;
        }
      }
    },
    async submitTotalOrder(bucket) {
      const relevantMonths = Object.keys(this.savedUpcOrders[bucket]["months"]).reduce((acc, item) => {
        if (this.savedUpcOrders[bucket]["months"][item]["shelfDate"]) {
          return [...acc, item];
        }
        return acc;
      }, []);
      for (let i = 0; i < relevantMonths.length; i++) {
        const monthShortName = relevantMonths[i];
        const requestedDelDate = this.reqDeliveryDate[bucket][monthShortName];
        const poNumber = this.poNumberObj[bucket][monthShortName];
        if (!requestedDelDate) {
          this.$overlay.show("Please Select a Date for " + monthShortName);
          setTimeout(this.$overlay.hide, 2000);
          return;
        }
        if (!poNumber) {
          this.$overlay.show("Please provide a PO number for  " + monthShortName);
          setTimeout(this.$overlay.hide, 2000);
          return;
        }
      }
      relevantMonths.forEach(async (monthShortName) => {
        const requestedDelDate = this.reqDeliveryDate[bucket][monthShortName];
        const poNumber = this.poNumberObj[bucket][monthShortName];
        await this.handleBBSubmit(bucket, monthShortName, requestedDelDate, poNumber);
        await this.deleteOrderAndModifyObjs(bucket, monthShortName);
      });
    },
    async submitOrder(bucket, monthShortName) {
      const requestedDelDate = this.reqDeliveryDate[bucket][monthShortName];
      const poNumber = this.poNumberObj[bucket][monthShortName];
      if (!requestedDelDate) {
        this.$overlay.show("Please Select a Date for " + monthShortName);
        setTimeout(this.$overlay.hide, 2000);
      } else if (!poNumber) {
        this.$overlay.show("Please provide a PO number for  " + monthShortName);
        setTimeout(this.$overlay.hide, 2000);
      } else {
        await this.handleBBSubmit(bucket, monthShortName, requestedDelDate, poNumber);
        await this.deleteOrderAndModifyObjs(bucket, monthShortName);
      }
    },

    async deleteOrderAndModifyObjs(bucket, monthShortName) {
      try {
        const materials = Object.keys(this.savedUpcOrders[bucket]["months"][monthShortName]["materials"]);
        await API.graphql(
          graphqlOperation(deleteOrder, {
            soldto_id: this.retailer.soldto_id,
            season: this.currentSeasonMutation,
            modified_by: this.user.name,
            monthIndex: monthsObject.filter((item) => item.shortName === monthShortName)[0].value,
            material_numbers: materials,
          })
        );
        //reset the bucket after submission
        this.savedUpcOrders[bucket]["months"][monthShortName] = { materials: {}, shelfDate: "" };
        this.reqDeliveryDate[bucket][monthShortName] = "";
        this.poNumberObj[bucket][monthShortName] = "";
      } catch (err) {
        this.$overlay.show("Could not delete upc orders after submission", err);
        setTimeout(this.$overlay.hide, 2000);
      }
    },

    // UPC ALLOCATION DROPDOWN SELECT EVENT HANDLER
    fetchLastSavedData() {
      this.loadIndicator = true;
      this.loadFirstTime = false;
      this.fetchUpc();
      this.fetchSubmittedToBBDataSummary();
    },
    async fetchSubmittedToBBDataSummary() {
      const submittedDataTmp = await API.graphql({
        query: fetchSubmittedToBbData,
        variables: {
          soldto_id: this.retailer.soldto_id,
          sorg: this.retailer.sorg,
          season: this.currentSeasonMutation,
          modified_by: this.user.name,
          currency_type: this.currencyType,
        },
      });
      this.submittedData = submittedDataTmp.data.fetchSubmittedToBbData;
    },
    // CALLED WHEN UPC DROPDOWN IS SELECTED
    async fetchUpc() {
      const { data: savedUpcData } = await API.graphql({
        query: fetchOrdersData,
        variables: {
          soldto_id: this.retailer.soldto_id,
          sorg: this.retailer.sorg,
          season: this.currentSeasonMutation,
          seasonYear: this.bookingYear,
          modified_by: this.user.name,
        },
      });
      this.savedUpcOrders = JSON.parse(savedUpcData.fetchOrdersData);
      //populate the structure for request delivery date ,  poNumber
      if (this.savedUpcOrders) {
        Object.keys(this.savedUpcOrders).forEach((item) => {
          this.reqDeliveryDate = { ...this.reqDeliveryDate, [item]: {} };
        });
        Object.keys(this.reqDeliveryDate).forEach((item) => {
          monthsObject.forEach((monthItem) => {
            this.reqDeliveryDate[item] = { ...this.reqDeliveryDate[item], [monthItem.shortName]: "" };
          });
        });
        this.poNumberObj = JSON.parse(JSON.stringify(this.reqDeliveryDate));
      }
      this.loadIndicator = false;
    },

    getWebOrdersHeader(bucket, webOrderLines, requestedDelDate, poNumber) {
      const productType = this.savedUpcOrders[bucket]["type"];
      const header = {
        Catalog: this.catalogId,
        CustPONo: poNumber,
        AssortmentID: this.order_id,
        DateRequested: requestedDelDate,
        DateOrdered: new Date().toISOString().slice(0, 10),
        DateCancelAfter: "",
        SoldTo: this.retailer.soldto_id.toString(),
        Buyer: this.user.name,
        Email: this.user.email,
        ShipTo: this.retailer.soldto_id.toString(),
        ProductType: productType,
        SalesID: "Retailer",
        TotalUnits: this.getSum(webOrderLines, null, "QtyOrdered"),
      };
      return header;
    },

    getWebOrderLines(bucket, monthShortName) {
      let lines = [];
      let orderLineNoCounter = 0;
      const materialObj = this.savedUpcOrders[bucket]["months"][monthShortName]["materials"];
      const upcs = Object.keys(materialObj).reduce((acc, item) => [...acc, ...materialObj[item]["upcs"]], []);
      upcs.forEach((item) => {
        const line = {
          OrderLineNo: ("000000" + (++orderLineNoCounter * 10).toString()).slice(-6),
          QtyOrdered: item.quantity,
          UPC: item.upc_number.toString(),
        };
        lines = [...lines, line];
      });
      return lines;
    },
    async handleBBSubmitDummy() {
      console.log("prevent default");
    },
    async handleBBSubmit(bucket, monthShortName, requestDelDate, poNumber) {
      const monthIndex = monthsObject.filter((item) => item.shortName === monthShortName)[0].value;
      this.saveDataError = "";
      const lines = this.getWebOrderLines(bucket, monthShortName);
      const header = this.getWebOrdersHeader(bucket, lines, requestDelDate, poNumber);
      this.bbSubmitData = { ...this.bbSubmitData, AuthenticationKey: this.uploadToBbUrlAuthKey };
      this.bbSubmitData = { ...this.bbSubmitData, WebOrders: [{ Header: header, Lines: lines }] };
      try {
        const bbResponse = await axios.post(this.uploadToBbUrl, this.bbSubmitData);
        const bbResponseSData = bbResponse.data;
        this.order_id =
          this.retailer.soldto_id +
          "_" +
          this.currentSeasonMutation +
          "_" +
          bucket +
          "_" +
          monthIndex +
          "_" +
          bbResponseSData.ConfirmationID;
        let responseData = [
          {
            order_id: this.order_id,
            soldto_id: this.retailer.soldto_id,
            season: this.currentSeasonMutation,
            bucket: bucket,
            month: monthIndex,
            po_number: poNumber,
            confirmation_id: bbResponseSData.ConfirmationID,
            response_code: bbResponseSData.ResponseCode,
            response_message: bbResponseSData.ResponseMessage,
            modified_by: this.user.name,
            last_modified_on: new Date().toLocaleString(),
            sorg: this.retailer.sorg,
            currency_type: this.currencyType,
            order_qty: this.getSum(lines, null, "QtyOrdered"),
          },
        ];
        try {
          await API.graphql(
            graphqlOperation(saveFinalOrderDataBbResponse, {
              saveFinalOrderDataBbResponse: responseData,
            })
          );
          this.$overlay.show(
            bbResponseSData.ResponseMessage + " Your Confirmation ID is " + bbResponseSData.ConfirmationID
          );
          setTimeout(this.$overlay.hide, 2000);
          this.fetchSubmittedToBBDataSummary();
          this.deleteOrderAndModifyObjs(bucket, monthShortName);
        } catch (err) {
          if (err["errors"][0]["message"].toUpperCase().includes("DUPLICATE")) {
            this.$overlay.show("This order has already been processed.");
            setTimeout(this.$overlay.hide, 2000);
          }
        }
      } catch (err) {
        this.$overlay.show("submission error", err);
        setTimeout(this.$overlay.hide, 2000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
$header_column_width: 250px;
$input_column_width: 85px;

.labels_th {
  min-width: $header_column_width;
  width: $header_column_width;
}

.upload-bb-header {
  background-color: #e6f2f9;
  padding: 10px;
  margin-top: 20px;
  p {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.table__month .table tbody tr {
  border-spacing: 0 10px;
  font-weight: 400;
}

.orderTableClass {
  td {
    padding: 0.4rem;
    border-top: none;
    text-align: center;
  }

  tr td:nth-child(1),
  tr td:nth-child(2) {
    font-weight: 800;
    padding: 6px 3px;
    width: 120px;
    box-sizing: border-box;
  }

  div {
    font-weight: 800;
    padding: 6px 3px;
    max-width: 120px;
    box-sizing: border-box;
  }
}

.catSubCatRow {
  font-weight: 500 !important;
}

.bgDisabledColor {
  background-color: rgb(184, 184, 184);
}

.orderSubmissionContainer {
  border: 1px solid #dadada;
  box-shadow: 0 10px 9px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 30px;
  padding: 20px 30px;
  margin: 20px;
  min-width: fit-content;
}

.row_flex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  input {
    min-width: 85px;
  }
}

.btn.btn-sm.btn-outline-primary {
  text-transform: uppercase;
  width: 85px;
  letter-spacing: 0.5px;
  &:hover {
    background-color: #e6f2f9;
    color: #000;
  }
  &.sumbmit-all {
    width: 100px;
  }

  &:disabled {
    background-color: rgb(184, 184, 184);
    color: #000;
  }
}

.btn.btn-sm.btn-outline-primary.totalButton {
  width: 100px;
}
</style>
